ion-card {
  margin: 0px;
  /* padding: 14.76px 23.73px 16.04px 20.26px; */
  padding: 14.76px 0px 18px 0px;
  border-radius: 0px;
  box-shadow: none;
  border-bottom: 1px solid #c4c4c4;
}

.flex {
  display: flex;
  /* background-color: var(--bg-color); */
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.card-icon {
  margin: 0px 10.33px 0px 20.26px;
}

.card-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  /* font-weight: 600; */
  font-size: 14.5px;
  line-height: 15px;
}

.br-btm {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10.95px;
}

.content-container {
  margin: 13.53px 23.73px 0px 20.26px;
}

.content-description {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #787878;
}

.read-more {
  color: var(--text-color);
  font-weight: 500;
}

.content-two {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: var(--text-color);
}

.forward-icon {
  margin-right: 10px;
}

.content-three {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-color);
}

.chip-container {
  background: #5087ec;
  border-radius: 15px;
  color: var(--text-white-color);
  width: 72px;
  justify-content: center;
  margin-right: 23.73px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
}

.justify-contain {
  justify-content: space-between;
}

.mb-11 {
  margin-bottom: 11px;
}

.profession-list {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 22px;
}

.profession-line {
  height: 4px;
  width: 42px;
  border-radius: 100px;
}

.profession-item {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

p {
  line-height: 25px;
  margin: 0;
}

.profession-title {
  font-weight: 400;
  font-size: 14px;
  color: var(--profession-gary-color);
  margin-top: 12px !important;
}

.profession-description {
  font-weight: 500;
  font-size: 15px;
  color: var(--text-color);
}

.line-blue {
  background-color: #66a0f7;
}

.line-green {
  background-color: #87cc66;
}

.line-purple {
  background-color: #ac85ff;
}

.line-orange {
  background-color: #ff8368;
}

.card-container .about-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-container .about-card-title-button {
  width: 88px;
  height: 33px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 9px;
  /* border: 1px solid #71757b;
  border-radius: 17px; */
  color: var(--card-button-color);
}

.education-card-list {
  display: flex;
  gap: 10px;
  margin-bottom: -30px;
  padding-bottom: 30px;
  overflow-x: auto;
}

.profession-about-card {
  padding-block: 48px 62.86px;
}

.education-about-card {
  padding-block: 44px 47.96px;
}

.accolades-about-card {
  padding-block: 47px 49px;
}

.achievement-about-card {
  padding-block: 43px 63px;
}

.bussiness-goals-about-card {
  padding-block: 60px 54px;
  /* gap: 51px; */
}

.business-needs-about-card {
  padding-block: 56px 48px;
}

.icebreakers-about-card {
  padding-block: 58px 51px;
}