ion-content {
  --keyboard-offset: 0 !important;
}

.browser-alert-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: var(--bg-color);
}

.browser-alert-heading {
  color: var(--text-color);
  max-width: 100%;
}

.overflow-hidden {
  --overflow: hidden;
}

.overflow-auto {
  --overflow: auto;
}

.anchor-clear {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500 !important;
}

.border_none {
  border: none !important;
}