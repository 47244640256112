.service-card {
  margin-inline: 10px;
}

.cards-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-block: 38px 41px;
}

.featured-services-cards {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-bottom: -30px;
  padding-bottom: 30px;
  overflow-x: auto;
}

.service-card-container {
  padding-block: 30px;
  display: flex;
  flex-direction: column;
  padding-inline: 26px 20px;
  gap: 35px;
}

.service-card-container .card-heading-lg {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 15px;
  color: var(--text-color);
}

.service-card-container .service-card-title {
  display: flex;
  justify-content: space-between;
  /* gap: 44px; */
  align-items: center;
}

/* .service-card-container .service-card-title-button {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --color: #71757b;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 9px;
  --border-color: #71757b;
  --border-width: 1px;
  --border-style: solid;
} */

/* Feature Services */

.feature-services-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.04em;
  --color: #9899a0;
}

/* .dashboard-icon{ 
  --background
} */
.dashboard-icon-section {
  background-color: #2a2c31;
  padding-block: 5px;
  padding-inline: 5px 14px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 18px;
  font-family: "Poppins";
}

.dashboard-image {
  height: 55px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-icon {
  position: relative;
  width: 57px;
  height: 55px;
  background-color: rgba(60, 62, 70, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-report-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-report-section h6 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: white;
}

.dashboard-report-section p {
  font-size: 13px;
  line-height: 16px;
  color: #8c93a1;
  padding-top: 4px;
}