.assist_card_container {
  display: flex;
  flex-direction: column;
  gap: 33px;
  padding-top: 10px;
}

.assist-header {
  display: flex;
  gap: 8.5px;
  /* padding-left: 25px; */
}

.more_options {
  position: absolute;
  right: 40px;
  top: 220px;
}

.color_line {
  flex-shrink: 0;
  width: 4px;
  height: 30px;
  background-color: #fedd71;
  border-radius: 100px;
}

.assist_details {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.assist_details h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.045em;
  color: #ffffff;
}

.assist_details p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  align-items: center;
  color: #858791;
}

.assist_details_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.assist_avatar {
  display: flex;
  gap: 15px;
  padding-top: 29px;
  padding-bottom: 30px;
  margin-bottom: -30px;
  overflow-x: auto;
}

.ionAvatar_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12.2px;
}

.assist_avatar .ion_avatar {
  height: 60px;
  width: 60px;
}

.ionlabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #aab2b7;
}

.Assist-Button {
  /* width: 85px; */
  margin: 0;
  /* padding: 10px; */
  border-radius: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14.0894px;
  line-height: 19px;
  color: #ffffff;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}

.assist-list {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: -30px;
  padding-bottom: 43px;
  overflow-x: auto;
}

.assist-item {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.supportbtn {
  margin-top: 20px;
  margin-left: auto;
  width: 130px;
  justify-content: space-between;
  display: flex;
  font-family: "Poppins";
}

.assist-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 9px;
  color: #606271;
}

.assist-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #aab2b7;
  min-width: 100px;
}

/* Assist detail List */
.children {
  width: 245px;
  padding-top: 33px;
  padding-bottom: 31px;
  overflow-x: auto;
}

.assist-list-card-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.assist-list-card-container:not(:last-child) .children {
  border-bottom: 1px solid #40414d;
}

.assist-list-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #606271;
  display: flex;
  flex-direction: column;
  gap: 14.09px;
}

.assist-list-card-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #606271;
}

.assist-line {
  height: 4px;
  width: 42px;
  border-radius: 100px;
}
.assist-line-blue {
  background-color: #4782f2;
}

.assist-line-green {
  background-color: #3ebf00;
}

.assist-line-purple {
  background-color: #c623e0;
}

.assist-line-red {
  background-color: #f61e2b;
}

.assist-line-yellow {
  background-color: #eeb600;
}

.detail-list {
  border-top: 2px solid #363a46;
}

.assist-about {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #adadad;
}

/* Stat data */

.stat-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.stat-data h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.stat-data p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  color: #606271;
}

.stat {
  display: flex;
  align-items: center;
  gap: 25px;
}

/* Support List Item */

.Support-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
}

.support-link-badge,
.support-message-badge {
  position: relative;
}

.support-link-badge::before,
.support-message-badge::before {
  content: attr(data-count);
  position: absolute;
  top: -16px;
  right: -20px;
  width: 18px;
  height: 18px;
  background: rgba(70, 73, 81, 0.62);
  display: grid;
  place-items: center;
  color: white;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  flex-shrink: 0;
}

/* Badge List */
.badge-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.badge-data p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #aab2b7;
}

.badge-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
}
