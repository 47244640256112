ion-content {
  width: 100%;
  height: 100%;
  --overflow: hidden;
  /* --background: #ccc url("../../assets/physician_girl_01.png") no-repeat center
    center / cover; */
}

ion-slides {
  height: 100%;
}
