.accolades_list {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.accolades-card {
  display: flex;
  gap: 15.26px;
  align-items: baseline;
}

.accolades-card svg {
  flex-shrink: 0;
}

.companyname {
  font-weight: 600;
  color: var(--text-color);
}

.accolades-card-detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.accolade-description {
  display: flex;
  flex-direction: column;
  gap: 12.11px;
}

.accolade-description p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.045em;
  max-width: 283px;
  color: var(--text-color);
}

.date {
  display: flex;
  align-items: center;
  gap: 6.86px;
}

.date p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17.36px;
  letter-spacing: 0.02em;
  color: #777777;
}