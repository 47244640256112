.toggle-container {
  justify-content: start;
  max-width: 280px;
  margin-left: -11px;
}

.toggle-button {
  --padding-end: 0;
  --padding-start: 0;
  min-width: auto;
  --ripple-color: transparent;
}

.toggle-button .label {
  padding: 6.6px 11.5px;
  font-size: 15px;
  font-weight: 400;
}

.segment-button-checked .label {
  background: #485565;
}

.active-card-list {
  display: flex;
  gap: 10px;
  margin-bottom: -30px;
  padding-bottom: 30px;
  overflow-x: auto;
}
