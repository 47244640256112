.achievement-card {
  display: flex;
  justify-content: baseline;
  gap: 15.26px;
}

.achievement-card svg {
  margin-top: -2px;
  flex-shrink: 0;
}

.achievement-description {
  display: flex;
  flex-direction: column;
  gap: 12.75px;
}

.achievement_title {
  color: #ffff;
  margin: 0;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.achievement-description .year {
  color: #437ff2;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 8px;
}

.descriptiontitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color);
}

.detail-block {
  display: flex;
  flex-direction: column;
  gap: 9.57px;
}

.details p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #727272;
}