.active_card_container {
  min-width: 296px;
  max-width: 296px;
  padding: 25px 15px;
  background-color: #24262b;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
}

.active_card_container .details {
  display: flex;
  gap: 15px;
}

.active_card_container .count {
  width: 27px;
  height: 27px;
  border-radius: 8.6px;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  font-size: 15px;
  font-weight: 600;
  color: white;
  font-family: "Poppins";
}

.count-purple {
  background: #5200ff;
}

.count-green {
  background: #49be1f;
}

.count-orange {
  background: #fe340f;
}

.count-yellow {
  background: #ffc202;
}

.details_data {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.details_data h6 {
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.details_data p {
  font-size: 14px;
  font-weight: 400;
  color: #8f97a6;
  margin: 0;
}

.details_data .progress {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.62px;
}

.active_card_footer {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}

.time_container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.time_text {
  font-size: 12px;
  line-height: 9px;
  font-family: "Poppins";
  color: #868fa0;
  margin-left: 6px;
}

.active_card_footer .icon_list {
  display: flex;
  gap: 20px;
}

.link_badge,
.message_badge {
  position: relative;
}

.link_badge::before,
.message_badge::before {
  content: attr(data-count);
  position: absolute;
  top: -12px;
  right: -12px;
  width: 15px;
  height: 15px;
  background: rgba(70, 73, 81, 0.62);
  display: grid;
  place-items: center;
  color: white;
  border-radius: 100px;
  font-size: 9px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: -0.05em;
}
