.bussiness-section {
  display: flex;
  flex-direction: row;
  gap: 17.78px;
  position: relative;
}

.bussiness-section h6:hover {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}

.bussiness-section:not(:last-child) {
  padding-bottom: 28px;
}

.bussiness-section:first-child .blue-Dot {
  background-color: #1e6eff;
  outline: none;
  border-radius: 100px;
}

.bussiness-section::before {
  content: "";
  border-left: 1px dashed #3b3c42;
  position: absolute;
  height: calc(100% - 18px);
  top: 18px;
  left: 9px;
}

.blue-Dot {
  display: flex;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
}

.business-list .bussiness-section:not(:first-child) .blue-Dot {
  border: 1px solid #3b3c42;
  border-radius: 100px;
}

.Bussiness-card {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  gap: 15px;
  width: 100%;
}

.business-list {
  margin-top: 20px;
}

.Business-card-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.business_desc,
.business_date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  color: #A8A8A8;
}

.business_date {
  color: #9798A3;
}

.Business-card-main h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.status-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .status-row .blue {
  background: #1e6eff;
} */

.status-row .color_Blue {
  background: #1e6eff;
}

.status-row .green {
  background: #27a53b;
}

.status-row p {
  padding: 8px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;

  border: none;
  border-radius: 10px;
}

.status-row .card-icons {
  display: flex;
  gap: 28px;
}