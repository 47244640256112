.education_list {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  margin-bottom: -30px;
  padding-bottom: 30px;
  margin-top: 20px;
}

.education_card_container {
  background-color: #24262b;
  border-radius: 18px;
  max-width: 282px;
  min-width: 282px;
  padding-block: 22px 26px;
  padding-inline: 14px;
  display: flex;
  gap: 12px;
  position: relative;
}

.education_card_container .education_color_line {
  width: 4px;
  height: 43px;
  /* background-color: #fedd71; */
  border-radius: 100px;

}

.color-blue {
  background-color: #66a0f7;
}

.color-red {
  background-color: #D8213A;
}

.color-green {
  background-color: #87cc66;
}

.color-yellow {
  background-color: #fedd71;
}

.color-orange {
  background-color: #ff8368;
}

.color-purple {
  background-color: #ac85ff;
}

.education_info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.education_details_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 2px solid #505767;
  padding-top: 4px;
}

.education_details_container img {
  width: 46px;
  height: 46px;
  border-radius: 100px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  right: 13px;
}

h6,
p {
  margin: 0 !important;
}

.education_details,
.education_info_text {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  gap: 6px;
}

.education_details h6 {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.045em;
  line-height: 14px;
  color: white;
}

.education_details p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #9B9DA4;
}

.education_info_text {
  margin-top: 14px;
}

.education_info_text h6 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #fafbff;
}

.education_info_text p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #787B7D;
}

.image-replacer {
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  position: absolute;
  top: 10px;
  right: 13px;
}

.image-replacer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}