.flex-col {
  flex-direction: column;
}

.gallery-card-container {
  max-width: 104.42px !important;
  min-height: 156.97px !important;
  line-height: 100%;
  background: #2a2c31;
  border-radius: 12px;
  margin-bottom: 14.68px;
}
.slider-card-container {
  min-height: 100%;
  max-width: 100%;
  background: #2a2c31;
  border-radius: 12px;
  margin-right: 9px;
}

.slider-title {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  color: #ffffff;
  padding-bottom: 2.68px;
}

.gallery-title {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 9px !important;
  line-height: 11px;
  color: #ffffff;
}
.mySwiper {
  margin-left: 15px;
}
.gallery-description {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 7.59863px !important;
  line-height: 9px;
  color: #989898;
  margin-top: 2.53px;
}

.slider-description {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 9.90032px !important;
  line-height: 12px;
  color: #989898;
}

.slider-padding {
  padding: 15.03px 8.66px 15.03px 7.66px;
}

.gallery-padding {
  padding: 11.53px 8.22px 11.74px 6.57px;
}

.swiper-image-container {
  padding-left: 23px;
  margin: 0 !important;
}

.align-left {
  text-align: left;
}

.slider-card-heading {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 16px;
  margin-left: 26px;
}

.slide-image-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.gallery-image-container {
  min-width: 104.79px;
  min-height: 105px;
  width: 100%;
}

.gallery-container {
  gap: 5.18px;
  padding: 19.25px 26px 32.6px 26px;
}

.flex-wrap {
  flex-wrap: wrap;
}
.load-more-btn {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 9.54648px !important;
  line-height: 10px;
  text-align: center;
  text-transform: capitalize;
  --box-shadow: none;
  --color: #000000 !important;
  --background: #ffffff;
  --border-radius: 8px;
  --border-width: 1px;
  --border-style: solid;
  width: 81.48px;
  height: 25.46px;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.prev-img-icon {
  --background: transparent;
  --box-shadow: none;
  --padding: 0px;
}

.next-img-icon {
  --background: transparent;
  --box-shadow: none;
  --padding: 0px;
}

.opacity-light {
  opacity: 0.5;
}

.opacity-dark {
  opacity: 1;
}

.swiper-image-container > div > .swiper-scrollbar {
  position: relative !important;
  background-color: #DEDADA;
  height: 1px !important;
  margin-top: 23.91px !important;
}
.borderbottom{
  border-bottom: none;
}
.swiper-image-container > div > .swiper-scrollbar .swiper-scrollbar-drag {
  background: #c03b3b;
}

.swiper-image-container .swiper-slide {
  height: auto;
}

.portfolio_container {
  margin: 0px !important;
  padding: 0 !important;
  border: none !important;
}

.portfolio-tab-segment-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-grey-color);
}

.portfolio-tab-segment-button-container {
  --indicatorbox-shadow: none;
  --background: 888888;
  border-bottom: 1px solid grey;
  min-height: 27.02px;
  padding: 0;
  border-radius: 0;
  border-top: none;
}
.portfolio-btn-tab-container > .segment-button-checked {
  --indicator-color: transparent;
  --indicator-box-shadow: none;
  border-radius: 0px;
  color: var(--text-color);
  border-bottom: 2px solid #e83030;
  font-weight: 600;
  border-top: none;
}

.portfolio-btn-tab-container {
  padding: 31px 22px 36px 26px;
  border-radius: 0;
  display: flex;
}
