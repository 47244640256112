ion-content {
  width: 100%;
  height: 100%;
  /* --background: #E8EBF1 !important; */
  --background: var(--bg-color) !important;
}

.profile-container ion-card {
  --background: var(--bg-color);
  /* border: none; */
  border-bottom: 1px solid var(--border-color);;
}
