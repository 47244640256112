.phone-card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icon-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}

.iconlabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #838489;
}

.Social-icons-list {
  display: flex;
  gap: 12.39px;
  max-width: 95%;
  margin-bottom: -30px;
  padding-bottom: 30px;
  overflow-x: auto;
}